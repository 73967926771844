.c1 {
  @apply max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl lg:max-w-4xl
}

.c2 {
  @apply pl-2 h-48 w-full sm:object-cover md:object-center md:h-full md:w-48 xs:p-4
}

.activemenuitem {
  @apply bg-gray-100 block px-4 py-2 text-sm text-gray-700
}

.deactivemenuitem {
  @apply block px-4 py-2 text-sm text-gray-700
}

.menuplans {
  @apply bg-gray-100 rounded-xl p-2
}

.menupanelbig {

  position:fixed;
  left:0px;

  @apply xs:hidden sm:hidden md:flex justify-center w-full bg-transparent left-0
}

//@apply xs:hidden sm:hidden md:block left-0

.menupanelbetween {
  border:1px solid #333333;
  border-radius:10px;
  z-index:99;
  background-color:#ffffff;
  position:fixed;
  left:0px;

  @apply w-fit justify-items-center mt-2
}

.menuhidden {
  @apply hidden
}

//ASK:hidden->inline-block varken animate olmuyor ondan opacity yapmak zorunda kaldim ama opacity de animasyona dahil oluyor
.menuitemArrow {
  color:#333333;
  @apply flex opacity-0 col-span-1 mr-0 h-5 w-5 self-center
}

.clickableHeader {
  &:hover {
    color:#528FCE;
  }
}

.clickableSubHeader {
  color:#878787;

  &:hover {
    color:#528FCE;
  }
}

.menuHeader {
  &:hover {
    @apply text-blue-500
  }

  color:#767676;

  @apply flex
}

.menuHeaderLink {
  &:hover .menuitemArrow {
    @apply inline-block opacity-100 translate-x-2 duration-500
  }

  &:hover {
    background-color:#9FCCF330;
    @apply rounded-xl
  }

  p {
    color:#333333;
  }

  color:#333333;

  font-weight:500;
  font-size:16px;

  @apply rounded flex max-w-xs justify-between pl-1 pr-2 pt-1 pb-1
}

.menuLink {
  &:hover .menuitemArrow {
    @apply inline-block opacity-100 translate-x-2 duration-500
  }

  &:hover {
    background-color:#9FCCF330;
    @apply rounded-xl
  }

  .href {
    color:#878787;
  }

  font-size:16px;

  @apply rounded px-2.5 py-1 flex text-left items-center justify-between max-w-xs
}

.collapseItem:hover {
  color:#528FCE;
}

.mobilemenuLink {
  color:#878787;
  @apply pl-1 pr-2 py-1.5
}

.imgscaleonscroll {
  background-color:#528FCE;
  @apply absolute table-row
}

.homepage {
  background-color:#ffffff;
  @apply pt-16 pb-2
}


.homepageMainSpace {

  //max-width:95vw;
  padding-left:20px;
  padding-right:20px;
  max-width:600px;

  @screen lg {
    max-width:1168px;
    //padding-left:0px;
    //padding-right:0px;
  }

  @apply flex flex-col mt-4 self-center w-full mx-auto
}

.businessMainSpace {
  @extend .homepageMainSpace;
  @apply xl:mt-[10vh]
}

.heroContainer {
  @apply h-full rounded-lg text-center flex flex-col items-center md:mb-12
}

.careerContainer {
  @apply h-full rounded-lg text-center flex flex-col items-center md:mt-12 md:mb-12
}

.personalHeroContainer {

  @screen lg {
    transform:translateY(50px);
  }

  @apply rounded-lg text-center flex flex-col items-center
  /*xl:mt-[16vh]*/
}

.businessHeroContainer {

  @screen lg {
    transform:translateY(50px);
  }

  @apply max-w-[1168px] self-center rounded-lg text-center flex flex-col items-center
  /*xl:mt-[16vh]*/
}

.heroTitle {
  line-height:35px;
  font-size:30px;
  color:#333333;
  margin-top:2rem;
  z-index:10;
  font-weight:500 !important;

  @screen lg {
    line-height:65px;
    font-size:60px;
  }

  @apply text-center w-full
}

.heroDescription {
  color:#333333;
  font-style:normal;
  font-weight:400;
  max-width:375px;
  font-size:24px;
  padding-bottom:1rem;

  line-height:30px;
  z-index:10;

  @screen lg {
    font-size:30px;
    max-width:1168px;
    line-height:55px;
  }

  @apply text-center pt-4
}


.businessVideo {

  @screen lg {
    transform:translateY(-100px)
  }

  @apply flex max-w-[1168px] flex-col lg:flex-row px-[20px] lg:px-0
}


.homepageHeroTitle {
  line-height:35px;
  font-size:30px;
  color:#333333;
  margin-top:2rem;
  z-index:10;

  @screen lg {
    line-height:65px;
    font-size:60px;
    text-align:left;
  }

  @apply text-center font-bold w-full
}

.agreeText {
  color:#333333;
  font-weight:400;
  max-width:100%;
  font-size:18px;
  line-height:27px;

  .agreeLink {
    font-weight:600;
    text-decoration:underline;
  }

  @apply self-center lg:self-start
}

.agreeLink {
  @extend .agreeText;
  font-weight:600;
  text-decoration:underline;
}

.homepageHeroDescription {
  color:#333333;
  font-style:normal;
  font-weight:400;
  max-width:100%;
  font-size:20px;
  padding-bottom:1rem;

  line-height:30px;

  @screen lg {
    font-size:30px;
    line-height:45px;
    text-align:left;
    @apply px-0
  }

  @apply text-center pt-4 px-6
}

.heroImageContainer {
  @apply w-full h-auto relative md:h-[100vh] md:flex md:flex-col md:justify-center
}

.blogTitle {
  @apply text-4xl text-black font-bold
}

.shareIcon {
  color:#00000075;
  fill:#00000075;

  &:hover {
    fill:#333333;
    color:#333333;
  }
}

.blogSummary {
  color:#666666;
  @apply text-base mt-2
}

.blogPost {

  font-size:small;

  @screen md {
    font-size:medium;
  }

  p {
    color:#333333;
  }

  h2 {
    color:#333333;
  }

  a {
    color:#00C6DE;
    word-wrap:break-word;
  }

}

.heroImageHome {
  padding-top:80px;

  @screen md {
    padding-top:0px;
  }
}

.heroImageBusiness {
  padding-top:50px;

  @screen md {
    padding-top:0px;
  }
}

.heroImageCompany {
  padding-top:0px;

  @screen md {
    padding-top:0px;
  }

  @apply w-full xxl:w-[2500px]
}

.hero {
  width:100%;
  position:relative;
  @apply md:flex md:flex-col md:justify-center overflow-hidden mb-16 md:min-h-[100vh]
}

.subscribeContainer {
  @apply flex justify-center md:justify-start pt-6
}

.btnSubscribe {
  background-color:#528FCE;
  align-items:center;
  justify-content:center;
  opacity:90%;
  color:#ffffff;
  font-size:18px;

  &:hover {
    opacity:100%;
  }

  &:disabled {
    background-color:gray;
  }

  @apply py-2 px-8 flex rounded-full
}


.popupCookies {
  background-color:#528FCE;
  align-items:end;
  justify-content:end;
  color:#ffffff;
  font-size:18px;

  @apply flex fixed z-50 inset-0
}


.navbarMobileMenuHeader {
  color:#333333;
  border-width:1px;
  border-color:#ffffff00;

  &:hover {
    background-color:#fff;
    border-width:1px;
    border-color:#333;
  }

  @apply px-3 py-2 rounded-full text-sm self-center text-center
}

.navbarMobileMenuHeaderSelected {
  color:#333333;
  background-color:#fff;
  border-width:1px;
  border-color:#333;

  @apply rounded-full px-3 py-2 rounded-full text-sm self-center text-center
}

.partnerImg {

  width:20%;

  @apply h-auto lg:w-44
}

.blogSidebarBig {
  @apply top-52
}

.blogSidebarSmall {
  @apply top-36
}

.blogBackIcon {
  &:hover {
    @apply rotate-90
  }

  @apply transition ease-in-out duration-300
}

.videoResponsive {
  aspect-ratio:16 / 9;
  width:100%;
}