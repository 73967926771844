
.navbarLogoFullScreen {
  height: 23px;
  width: 203px;

  @apply hidden lg:block
}

.navbarLogoOtherPages {
  @apply block;
  height: 23px;
  width: 203px;

}

.btnSubscribe {
  font-size: 18px;

  @apply hidden md:inline-flex items-center px-6 py-2 rounded-md shadow-sm text-white
}



.subscribeContainer {
  background-color: #528FCE;
  height: 50%;
  opacity: 90%;

  &:hover {
    opacity: 100%;
  }

  @apply absolute inset-y-0 right-0 hidden sm:flex gap-2 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0
}

.subscribeContainerDisclosure {
  background-color: #528FCE;

  opacity: 90%;

  &:hover {
    opacity: 100%;
  }

  @apply right-0 flex gap-2 items-center pr-2 pl-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 sm:pl-0
}

.panelTitle {
  @apply px-3 py-2 rounded-md text-sm self-center text-center
}

.panelTitleUnselected {
  color:#333333;
  border-width: 1px;
  border-color: #ffffff00;
  @apply rounded-full
}

.panelTitleSelected {
  color:#333333;
  background-color: #fff;
  border-width: 1px;
  border-color: #333;

  @apply rounded-full
}

.logoContainer {
  @apply flex-1 flex items-center justify-center md:items-stretch md:justify-start
}

.topBar {
  max-width: 1168px;
  padding-left: 20px;
  padding-right: 20px;
  @apply relative flex items-center justify-between h-16 lg:mx-auto
}

.miniMenu {
  @apply inline-flex items-center justify-center p-2 rounded-md text-black hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden
}

.navbarIconContainer {

  &:hover {

    .navbarIcon{
      display: none;
    }
    .navbarIconHover{
      display: block;
    }

    @apply text-gray-600 bg-transparent
  }

  @apply bg-black text-white aspect-square flex justify-center items-center w-8 rounded-full
}

.navbarIcon{
  display: block;
}
.navbarIconHover{
  display: none;
}
